export const PlayIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M4.5 5.65289C4.5 4.22589 6.029 3.32289 7.279 4.00989L18.819 10.3569C20.114 11.0689 20.114 12.9299 18.819 13.6429L7.28 19.9899C6.03 20.6769 4.501 19.7729 4.501 18.3469L4.5 5.65289Z" fill="black" />
        </g>
    </svg>
);

export const BrushIcon = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M12.4913 22.9217C17.6388 16.9407 29.298 5.25805 34.2312 5.0075C37.2832 4.71518 31.203 15.5425 16.7975 27.3899M19.0968 16.7416L22.8595 20.5416M5 34.7577C6.18247 30.5787 5.43645 32.6324 5.84012 27.8199C6.0551 27.1074 6.48763 24.8962 9.1893 23.7942C12.2603 22.5416 14.5116 24.4352 15.0935 25.3251C16.8078 27.1837 17.0065 29.4921 15.0935 32.1291C13.1805 34.7661 7.50587 35.4212 5 34.7577Z" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const PlainIcon = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M19.6682 10.8159L22.1442 8.33995C24.932 5.55209 28.5818 4.3957 32.4445 4.20674C33.947 4.13324 34.6982 4.09649 35.3005 4.69889C35.903 5.30127 35.8662 6.05247 35.7927 7.5549C35.6037 11.4176 34.4473 15.0675 31.6595 17.8553L29.1835 20.3313C27.1445 22.3703 26.5648 22.9501 26.9928 25.1618C27.4153 26.8513 27.8242 28.4873 26.5957 29.7158C25.1055 31.2059 23.7462 31.2059 22.256 29.7158L10.2836 17.7434C8.7935 16.2532 8.79345 14.8939 10.2836 13.4037C11.5121 12.1752 13.1482 12.5842 14.8376 13.0066C17.0493 13.4346 17.6292 12.8549 19.6682 10.8159Z" stroke="#446DFC" stroke-width="2.5" stroke-linejoin="round" />
            <path id="Vector_2" d="M28.3262 11.6667H28.3412" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_3" d="M4.16602 35.8333L12.4993 27.5" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" />
            <path id="Vector_4" d="M14.166 35.8333L17.4993 32.5" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" />
            <path id="Vector_5" d="M4.16602 25.8333L7.49935 22.5" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" />
        </g>
    </svg>
);

export const EmojiIcon = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M20.0007 36.6666C29.2054 36.6666 36.6673 29.2047 36.6673 19.9999C36.6673 10.7952 29.2054 3.33325 20.0007 3.33325C10.7959 3.33325 3.33398 10.7952 3.33398 19.9999C3.33398 29.2047 10.7959 36.6666 20.0007 36.6666Z" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_2" d="M13.334 25C14.8542 27.024 17.2745 28.3333 20.0007 28.3333C22.7268 28.3333 25.1472 27.024 26.6673 25" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_3" d="M13.3489 15H13.334M26.6673 15H26.6523" stroke="#446DFC" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const FacebookIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z" stroke="#446DFC" stroke-width="1.5" stroke-linejoin="round" />
        </g>
    </svg>
);

export const InstagramIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="#446DFC" stroke-width="1.5" stroke-linejoin="round" />
            <path id="Vector_2" d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="#446DFC" stroke-width="1.5" />
            <path id="Vector_3" d="M17.507 6.5H17.498" stroke="#446DFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const TwitterIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M3 21L10.5484 13.4516M10.5484 13.4516L3 3H8L13.4516 10.5484M10.5484 13.4516L16 21H21L13.4516 10.5484M21 3L13.4516 10.5484" stroke="#446DFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const YouTubeIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M12 20.5C13.8097 20.5 15.5451 20.3212 17.1534 19.9934C19.1623 19.5839 20.1668 19.3791 21.0834 18.2006C22 17.0221 22 15.6693 22 12.9635V11.0365C22 8.33073 22 6.97787 21.0834 5.79937C20.1668 4.62088 19.1623 4.41613 17.1534 4.00662C15.5451 3.67877 13.8097 3.5 12 3.5C10.1903 3.5 8.45489 3.67877 6.84656 4.00662C4.83766 4.41613 3.83321 4.62088 2.9166 5.79937C2 6.97787 2 8.33073 2 11.0365V12.9635C2 15.6693 2 17.0221 2.9166 18.2006C3.83321 19.3791 4.83766 19.5839 6.84656 19.9934C8.45489 20.3212 10.1903 20.5 12 20.5Z" stroke="#446DFC" stroke-width="1.5" />
            <path id="Vector_2" d="M15.9621 12.3129C15.8137 12.9187 15.0241 13.3538 13.4449 14.2241C11.7272 15.1705 10.8684 15.6438 10.1728 15.4615C9.9372 15.3997 9.7202 15.2911 9.53799 15.1438C9 14.7089 9 13.8059 9 12C9 10.1941 9 9.29112 9.53799 8.85618C9.7202 8.70886 9.9372 8.60029 10.1728 8.53854C10.8684 8.35621 11.7272 8.82945 13.4449 9.77593C15.0241 10.6462 15.8137 11.0813 15.9621 11.6871C16.0126 11.8933 16.0126 12.1067 15.9621 12.3129Z" stroke="#446DFC" stroke-width="1.5" stroke-linejoin="round" />
        </g>
    </svg>
);
export const LinkedInIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M7 10V17" stroke="#446DFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_2" d="M11 13V17M11 13C11 11.3431 12.3431 10 14 10C15.6569 10 17 11.3431 17 13V17M11 13V10" stroke="#446DFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_3" d="M7.00704 7H6.99805" stroke="#446DFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_4" d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="#446DFC" stroke-width="1.5" stroke-linejoin="round" />
        </g>
    </svg>
);

export const EmailIcon = () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M32.625 10.125V25.875C32.625 26.7701 32.2694 27.6285 31.6365 28.2615C31.0035 28.8944 30.1451 29.25 29.25 29.25H6.75C5.85489 29.25 4.99645 28.8944 4.36351 28.2615C3.73058 27.6285 3.375 26.7701 3.375 25.875V10.125M32.625 10.125C32.625 9.22989 32.2694 8.37145 31.6365 7.73851C31.0035 7.10558 30.1451 6.75 29.25 6.75H6.75C5.85489 6.75 4.99645 7.10558 4.36351 7.73851C3.73058 8.37145 3.375 9.22989 3.375 10.125M32.625 10.125V10.4895C32.6251 11.0657 32.4776 11.6324 32.1966 12.1354C31.9157 12.6385 31.5106 13.0613 31.02 13.3635L19.77 20.286C19.2378 20.6138 18.625 20.7874 18 20.7874C17.375 20.7874 16.7622 20.6138 16.23 20.286L4.98 13.365C4.48938 13.0628 4.0843 12.64 3.80335 12.1369C3.5224 11.6339 3.37494 11.0672 3.375 10.491V10.125" stroke="#446DFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const ChevronRightIconIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M8.25 4.5L15.75 12L8.25 19.5" stroke="#446DFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const ChevronUpIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M3.75 13.125L10 6.875L16.25 13.125" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const ChevronDownIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M16.25 6.875L10 13.125L3.75 6.875" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const ChevronRightIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M18.375 22.75L9.625 14L18.375 5.25" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const ChevronLeftIcon = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M9.625 22.75L18.375 14L9.625 5.25" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const SkipForwadIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M15.9351 11.3742C15.6807 10.1626 14.3271 9.2923 11.6198 7.5519C8.6753 5.6589 7.203 4.7124 6.0105 5.0771C5.6066 5.2006 5.2346 5.4177 4.9223 5.7124C4 6.5822 4 8.3882 4 12C4 15.6118 4 17.4178 4.9223 18.2877C5.2346 18.5823 5.6066 18.7994 6.0105 18.9229C7.203 19.2876 8.6753 18.3411 11.6198 16.4481C14.3271 14.7077 15.6807 13.8374 15.9351 12.6258C16.0216 12.2135 16.0216 11.7865 15.9351 11.3742Z" stroke="#1E2022" stroke-width="1.5" stroke-linejoin="round" />
            <path id="Vector_2" d="M20 20L20 4" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" />
        </g>
    </svg>

);

export const SkipBackIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M8.06492 12.6258C8.31931 13.8374 9.67295 14.7077 12.3802 16.4481C15.3247 18.3411 16.797 19.2876 17.9895 18.9229C18.3934 18.7994 18.7654 18.5823 19.0777 18.2876C20 17.4178 20 15.6118 20 12C20 8.38816 20 6.58224 19.0777 5.71235C18.7654 5.41773 18.3934 5.20057 17.9895 5.07707C16.797 4.71243 15.3247 5.6589 12.3802 7.55186C9.67295 9.29233 8.31931 10.1626 8.06492 11.3742C7.97836 11.7865 7.97836 12.2135 8.06492 12.6258Z" stroke="#1E2022" stroke-width="1.5" stroke-linejoin="round" />
            <path id="Vector_2" d="M4 4V20" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" />
        </g>
    </svg>
);

export const PauseSongIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M16.0003 29.3334C23.3641 29.3334 29.3337 23.3639 29.3337 16.0001C29.3337 8.63628 23.3641 2.66675 16.0003 2.66675C8.63653 2.66675 2.66699 8.63628 2.66699 16.0001C2.66699 23.3639 8.63653 29.3334 16.0003 29.3334Z" stroke="#1E2022" stroke-width="1.5" />
            <path id="Vector_2" d="M21.2604 16.5263C21.0248 17.3619 19.9111 17.9524 17.6839 19.1335C15.5307 20.2751 14.4541 20.846 13.5865 20.6166C13.2279 20.5216 12.9011 20.3415 12.6375 20.0934C12 19.493 12 18.3286 12 15.9999C12 13.6713 12 12.5068 12.6375 11.9065C12.9011 11.6583 13.2279 11.4782 13.5865 11.3833C14.4541 11.1538 15.5307 11.7247 17.6839 12.8664C19.9111 14.0474 21.0248 14.6379 21.2604 15.4735C21.3576 15.8184 21.3576 16.1814 21.2604 16.5263Z" stroke="#1E2022" stroke-width="1.5" stroke-linejoin="round" />
        </g>
    </svg>
);

export const VolumeIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M14 14.8135V9.18646C14 6.04126 14 4.46866 13.0747 4.0773C12.1494 3.68593 11.0603 4.79793 8.88232 7.02192C7.75439 8.17365 7.11085 8.42869 5.50604 8.42869C4.10257 8.42869 3.40084 8.42869 2.89675 8.77262C1.85035 9.48655 2.00852 10.882 2.00852 12C2.00852 13.118 1.85035 14.5134 2.89675 15.2274C3.40084 15.5713 4.10257 15.5713 5.50604 15.5713C7.11085 15.5713 7.75439 15.8264 8.88232 16.9781C11.0603 19.2021 12.1494 20.3141 13.0747 19.9227C14 19.5313 14 17.9587 14 14.8135Z" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_2" d="M17 9C17.6254 9.81968 18 10.8634 18 12C18 13.1366 17.6254 14.1803 17 15" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path id="Vector_3" d="M20 7C21.2508 8.36613 22 10.1057 22 12C22 13.8943 21.2508 15.6339 20 17" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const CancelIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path id="Vector" d="M6 18L18 6M6 6L18 18" stroke="#1E2022" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
);

export const ExpandIcon = () => (
    <svg width="24" height="24"  viewBox="0 0 1792 1792"  xmlns="http://www.w3.org/2000/svg">
        <path
            d="M883 1056q0 13-10 23l-332 332 144 144q19 19 19 45t-19 45-45 19h-448q-26 0-45-19t-19-45v-448q0-26 19-45t45-19 45 19l144 144 332-332q10-10 23-10t23 10l114 114q10 10 10 23zm781-864v448q0 26-19 45t-45 19-45-19l-144-144-332 332q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l332-332-144-144q-19-19-19-45t19-45 45-19h448q26 0 45 19t19 45z"
            fill="#446dfc"
        />
    </svg>
);

export const MobilePauseIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame">
      <path id="Vector" d="M16.0003 29.3334C23.3641 29.3334 29.3337 23.3639 29.3337 16.0001C29.3337 8.63628 23.3641 2.66675 16.0003 2.66675C8.63653 2.66675 2.66699 8.63628 2.66699 16.0001C2.66699 23.3639 8.63653 29.3334 16.0003 29.3334Z" stroke="white" stroke-width="1.5"/>
      <path id="Vector_2" d="M21.2604 16.5263C21.0248 17.3619 19.9111 17.9524 17.6839 19.1335C15.5307 20.2751 14.4541 20.846 13.5865 20.6166C13.2279 20.5216 12.9011 20.3415 12.6375 20.0934C12 19.493 12 18.3286 12 15.9999C12 13.6713 12 12.5068 12.6375 11.9065C12.9011 11.6583 13.2279 11.4782 13.5865 11.3833C14.4541 11.1538 15.5307 11.7247 17.6839 12.8664C19.9111 14.0474 21.0248 14.6379 21.2604 15.4735C21.3576 15.8184 21.3576 16.1814 21.2604 16.5263Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
    </g>
  </svg>
);

export const MobilePlayIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pause"><rect x="14" y="4" width="4" height="16" rx="1"/><rect x="6" y="4" width="4" height="16" rx="1"/></svg>
  
);

export const MobileBackIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Frame">
    <path id="Vector" d="M8.06492 12.6258C8.31931 13.8374 9.67295 14.7077 12.3802 16.4481C15.3247 18.3411 16.797 19.2876 17.9895 18.9229C18.3934 18.7994 18.7654 18.5823 19.0777 18.2876C20 17.4178 20 15.6118 20 12C20 8.38816 20 6.58224 19.0777 5.71235C18.7654 5.41773 18.3934 5.20057 17.9895 5.07707C16.797 4.71243 15.3247 5.6589 12.3802 7.55186C9.67295 9.29233 8.31931 10.1626 8.06492 11.3742C7.97836 11.7865 7.97836 12.2135 8.06492 12.6258Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linejoin="round"/>
    <path id="Vector_2" d="M4 4V20" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
  </g>
</svg>
);

export const MobileForwardIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame">
      <path id="Vector" d="M15.9351 11.3742C15.6807 10.1626 14.3271 9.2923 11.6198 7.5519C8.6753 5.6589 7.203 4.7124 6.0105 5.0771C5.6066 5.2006 5.2346 5.4177 4.9223 5.7124C4 6.5822 4 8.3882 4 12C4 15.6118 4 17.4178 4.9223 18.2877C5.2346 18.5823 5.6066 18.7994 6.0105 18.9229C7.203 19.2876 8.6753 18.3411 11.6198 16.4481C14.3271 14.7077 15.6807 13.8374 15.9351 12.6258C16.0216 12.2135 16.0216 11.7865 15.9351 11.3742Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linejoin="round"/>
      <path id="Vector_2" d="M20 20L20 4" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
    </g>
  </svg>
);





