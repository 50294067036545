import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import myIcon from '../../assets/icons/Logo.png';
import { Menu, X } from 'lucide-react'; // Import icons from Lucide React

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Function to handle menu link click
    const handleLinkClick = () => {
        setIsMenuOpen(false); // Close menu when a link is clicked
    };

    return (
        <header className="py-4 font-sans">
            <div className="container mx-auto flex items-center justify-between">
                <div className="max-w-7xl flex items-center justify-start">
                    <div className="flex items-center">
                        <Link to="/" className="flex items-center space-x-2 h-[2rem]">
                            <img src={myIcon} alt="App logo" className="w-full h-full object-contain" />
                        </Link>
                    </div>
                    <nav className="hidden md:flex items-center space-x-8 ml-6">
                        <Link to="/browse-music" className="text-[#1e2022] text-base font-medium font-['Montserrat'] leading-normal">
                            Browse Music
                        </Link>
                        <a href="#why-digital-bridge" className="text-[#1e2022] text-base font-medium font-['Montserrat'] leading-normal">
                            Why Digital Bridge
                        </a>
                        <a href="#faqs" className="text-[#1e2022] text-base font-medium font-['Montserrat'] leading-normal">
                            FAQ's
                        </a>
                        <Link to="/contact" className="text-[#1e2022] text-base font-medium font-['Montserrat'] leading-normal">
                            Contact Us
                        </Link>
                    </nav>
                </div>
                <button
                    className="md:hidden"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    aria-label="Toggle menu"
                >
                    {isMenuOpen ? (
                        <X className="w-6 h-6 text-gray-800" />
                    ) : (
                        <Menu className="w-6 h-6 text-gray-800" />
                    )}
                </button>
            </div>
            {isMenuOpen && (
                <div className="md:hidden mt-4">
                    <nav className="flex flex-col space-y-4 px-4">
                        <Link
                            to="/browse-music"
                            className="text-gray-600 hover:text-gray-900"
                            onClick={handleLinkClick}
                        >
                            Browse Music
                        </Link>
                        <a
                            href="#why-digital-bridge"
                            className="text-gray-600 hover:text-gray-900"
                            onClick={handleLinkClick}
                        >
                            Why Digital Bridge
                        </a>
                        <a
                            href="#faqs"
                            className="text-gray-600 hover:text-gray-900"
                            onClick={handleLinkClick}
                        >
                            FAQ's
                        </a>
                        <Link
                            to="/contact"
                            className="text-gray-600 hover:text-gray-900"
                            onClick={handleLinkClick}
                        >
                            Contact Us
                        </Link>
                    </nav>
                </div>
            )}
        </header>
    );
}

export default Header;
