import React from 'react';
import galleryimages from '../../assets/galleryImages/galleryimages.jpg';

function Home() {
    return (
        <section className="bg-[#EBF5FF] font-sans">
            <div className="flex flex-col md:flex-row container mx-auto">
                <div className="flex-1 mb-8 md:mb-0 text-center md:text-left py-16 md:pr-10">
                    <h1 className="text-[#1E2022] text-3xl md:text-5xl font-bold leading-tight">
                        Elevate Your YouTube Shorts
                    </h1>
                    <p className="text-[#323232] text-xl font-normal font-['Montserrat'] leading-7 my-4">
                        Access our premium music library packed with trending tracks to complement any content style.
                    </p>
                    <button className="h-12 px-6 bg-[#446DFC] text-white rounded-lg text-lg font-semibold">
                        Browse Music
                    </button>
                    <div className="border-t border-[#c8d4fe] my-6"></div>
                    <div className="flex flex-wrap justify-between  gap-y-6 md:gap-y-0">
                        <div className="w-full md:w-auto flex-1">
                            <p className="text-[#323232] text-sm font-normal font-['Montserrat'] leading-tight">UP TO</p>
                            <p className="text-[#1e2022] text-5xl font-bold font-['Montserrat'] leading-[56px]">500+</p>
                            <p className="text-[#323232] text-sm font-normal font-['Montserrat'] leading-tight">YouTube Creators</p>
                        </div>
                        <div className="w-full md:w-auto flex-1">
                            <p className="text-[#323232] text-sm font-normal font-['Montserrat'] leading-tight">UP TO</p>
                            <p className="text-[#1e2022] text-5xl font-bold font-['Montserrat'] leading-[56px]">2B+</p>
                            <p className="text-[#323232] text-sm font-normal font-['Montserrat'] leading-tight">Monthly Views</p>
                        </div>
                        <div className="w-full md:w-auto flex-1">
                            <p className="text-[#323232] text-sm font-normal font-['Montserrat'] leading-tight">UP TO</p>
                            <p className="text-[#1e2022] text-5xl font-bold font-['Montserrat'] leading-[56px]">20,000+</p>
                            <p className="text-[#323232] text-sm font-normal font-['Montserrat'] leading-tight">Shorts Improved</p>
                        </div>
                    </div>

                </div>
                <div className="flex-1 h-full">
                    <img
                        src={galleryimages}
                        alt="Gallery"
                        className="w-full h-full object-contain"
                    />
                </div>
            </div>
        </section>
    );
}

export default Home;
