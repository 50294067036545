import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { Plus } from "lucide-react";

import { cn } from "../../lib/utills";

const Accordion = React.forwardRef(({ className, ...props }, ref) => (
    <AccordionPrimitive.Root
        ref={ref}
        className={cn(
            "border border-[#c8d4fe] rounded-md bg-blue-50 shadow-sm text-left", // Outer container border and rounded corners
            className
        )}
        {...props}
    />
));
Accordion.displayName = AccordionPrimitive.Root.displayName;

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn(
            "border-t border-[#c8d4fe]", // Top border only, shared between items
            "first:border-t-0", // Remove the top border for the first item
            "text-left",
            className
        )}
        {...props}
    />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                "flex flex-1 items-center justify-between px-6 py-4 text-[#323232] font-bold font-['Montserrat'] leading-7 transition-colors", // Base styles
                "focus:outline-none", // Remove focus outline
                "md:justify-between justify-start", // Mobile: left-align content, Desktop: space-between
                "text-base md:text-xl", // Smaller text on mobile (default: text-xl)
                "text-left",
                className
            )}
            {...props}
        >
            {children}
            <Plus className="h-4 w-4 shrink-0 transition-transform duration-200 md:ml-0 ml-auto" style={{ stroke: "#446dfc" }} />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className={cn(
            "overflow-hidden px-6 py-4 text-sm text-gray-600 transition-all bg-white", // Increased padding: px-6 and py-4
            className
        )}
        {...props}
    >
        <div>{children}</div>
    </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
