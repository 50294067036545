"use client"

import * as React from "react"
import * as SliderPrimitive from "@radix-ui/react-slider"

import { cn } from "../../lib/utills"

const Slider = React.forwardRef(({ className, ...props }, ref) => (
    <SliderPrimitive.Root
        ref={ref}
        className={cn("relative flex w-full touch-none select-none items-center", className)}
        {...props}>
        <SliderPrimitive.Track
            className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-[#d1d4d6]">
            <SliderPrimitive.Range className="absolute h-full bg-[#446dfc]" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
            className="block h-4 w-4 rounded-full bg-[#446dfc] shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }

