import React from 'react';
import { PlayIcon } from '../../assets/icons/index';
import Pop from '../../assets/MusicList/Pop.png';
import Electronic from '../../assets/MusicList/Electronic.png';
import HipHop from '../../assets/MusicList/Hip Hop.png';
import Cinematic from '../../assets/MusicList/Cinematic.png';
import Classical from '../../assets/MusicList/Classical.png';
import Acoustic from '../../assets/MusicList/Acoustic.png';

function Index() {
    return (
        <section className="font-sans bg-gradient-to-t from-[#446dfc] to-[#010206]">
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 py-8 md:py-0  md:grid-cols-3 lg:grid-cols-6 gap-6 rounded">
                {[
                    { title: "Pop", color: "#ff6ec7", image: Pop },
                    { title: "Electronic", color: "#4b88ff", image: Electronic },
                    { title: "Hip Hop", color: "#ffd700", image: HipHop },
                    { title: "Cinematic", color: "#af6feb", image: Cinematic },
                    { title: "Classical", color: "#00cccc", image: Classical },
                    { title: "Acoustic", color: "#ffa07a", image: Acoustic },
                ].map((item, index) => (
                    <div
                        key={index}
                        className="w-full md:py-[80px] bg-transparent flex flex-col items-start relative transition-transform duration-300 hover:scale-105"
                    >
                        <div className={`w-full h-12 rounded-sm`} style={{ backgroundColor: item.color }}>
                            <div className="pl-3 text-[#1e2022] text-2xl font-bold font-['Montserrat'] leading-loose">
                                {item.title}
                            </div>
                        </div>

                        {/* Image with Play Icon */}
                        <div className="relative w-full h-[120px]">
                            <img
                                src={item.image}
                                alt={`${item.title} thumbnail`}
                                className="w-full h-full object-cover"
                            />
                            <div className="absolute bottom-1 right-2 flex justify-center items-center w-12 h-12 bg-white rounded-full transition-transform duration-300 hover:scale-110">
                                <PlayIcon />
                            </div>
                        </div>

                        {/* Bottom Section with Title */}
                        <div className="w-full h-9 bg-[#212328] rounded-b">
                            <div className="pl-3 pt-2 text-white text-sm font-medium font-['Montserrat'] leading-tight">
                                {item.title}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Index;
