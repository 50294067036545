import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Layouts/Header'
import Footer from './components/Layouts/Footer'
import Home from './pages/Home';
import ContactForm from './pages/ContactUs';
import BrowseMusic from './pages/BrowseMusic'
import { TrackDetails } from './components/BrowseMusic/trackdetails';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-white">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/browse-music" element={<BrowseMusic />} />
          <Route path="/single-track" element={<TrackDetails />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

