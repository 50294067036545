"use client"

import * as React from "react"
import { Pause, ChevronDown } from 'lucide-react'
import {
    SkipBackIcon,
    PauseSongIcon,
    SkipForwadIcon,
    CancelIcon,
    VolumeIcon,
    ExpandIcon,
    MobilePauseIcon,
    MobilePlayIcon,
    MobileBackIcon,
    MobileForwardIcon
} from "../../assets/icons/index"
import { MusicProgress } from "./musicprogress"
import { useNavigate } from "react-router-dom"

export function AudioPlayer({ track, onClose }) {
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(5);
    const [volume, setVolume] = React.useState(0);
    const duration = 169;
    const audioRef = React.useRef();
    const navigate = useNavigate();


    React.useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime((prev) => (prev >= duration ? 5 : prev + 1));
        }, 1000);

        return () => clearInterval(timer);
    }, [duration]);

    const handleVolumeChange = (e) => {
        setVolume(e.target.value);
    };

    React.useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume / 100;
        }
    }, [volume]);

    if (!track) return null;

    const redirect = () => {
        navigate("/single-track", { state: { track } });
    };

    return (
        <div className="w-full fixed bottom-0 left-0 right-0 bg-white">
            {/* Mobile View */}
            <div className="fixed md:hidden inset-0 flex flex-col items-center bg-black text-white p-4 z-50">
                <div className="w-full flex justify-between items-center mb-4">
                    <span className="text-sm">Playing Music</span>
                    <button onClick={onClose}>
                        <ChevronDown className="h-5 w-5" />
                    </button>
                </div>
                <div className="w-64 h-64 bg-gradient-to-br from-orange-400 to-orange-600 rounded-lg flex items-center justify-center mb-6">
                    {track.image ? (
                        <img
                            src={track.image}
                            alt={track.title}
                            className="w-full h-full object-cover rounded-lg"
                        />
                    ) : (
                        <div className="w-full h-full flex items-center justify-center">
                            <svg
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-16 h-16"
                            >
                                <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z" />
                            </svg>
                        </div>
                    )}
                </div>
                <div className="w-full text-center mb-6">
                    <h3 className="text-lg  font-bold mb-1 truncate">{track.title}</h3>
                    <p className="text-xs text-gray-400 truncate">{track.artist}</p>
                </div>
                <div className="w-full max-w-xs mb-6">
                    <MusicProgress
                        currentTime={currentTime}
                        duration={duration}
                        onSeek={setCurrentTime}
                    />
                </div>
                <div className="flex items-center justify-between gap-4">
                    <button className="text-white  hover:scale-105 transition-transform">
                        <MobileBackIcon className="w-6 h-6" />
                    </button>
                    <button
                        className="w-14 h-14 flex items-center justify-center rounded-full  text-black hover:scale-110 transition-transform"
                        onClick={() => setIsPlaying(!isPlaying)}
                    >
                        {isPlaying ? <MobilePlayIcon className="h-6 w-6" /> : <MobilePauseIcon />}
                    </button>
                    <button className="text-white hover:scale-105 transition-transform">
                        <MobileForwardIcon className="w-6 h-6" />
                    </button>
                </div>
            </div>
            {/* Desktop View */}
            <div className="hidden md:flex items-center justify-between gap-6 px-6 py-3 h-auto bg-white shadow-2xl">
                <div className="flex items-center gap-4 w-full md:w-[210px]">
                    <div className="relative group">
                        <img
                            src={track.image}
                            alt={track.title}
                            className="w-12 h-12 rounded object-cover"
                        />
                        <button className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity">
                            <ChevronDown className="h-4 w-4 text-white" />
                        </button>
                    </div>
                    <div className="min-w-0">
                        <h3 className="text-[#1e2022] text-sm font-bold font-['Montserrat'] leading-tight truncate">
                            {track.title}
                        </h3>
                        <p className="mt-1 text-[#1e2022] text-[13px] font-normal font-['Montserrat'] leading-none truncate">
                            {track.artist}
                        </p>
                    </div>
                </div>

                <div className="flex items-center gap-6 w-full md:w-auto">
                    <button className="text-gray-400 transition-colors">
                        <SkipBackIcon />
                    </button>
                    <button
                        className="w-10 h-10 flex items-center justify-center rounded-full bg-white text-black hover:scale-105 transition-transform"
                        onClick={() => setIsPlaying(!isPlaying)}
                    >
                        {isPlaying ? <Pause className="h-5 w-5" /> : <PauseSongIcon className="h-5 w-5" />}
                    </button>
                    <button className="text-gray-400 transition-colors">
                        <SkipForwadIcon />
                    </button>
                </div>

                <div className="flex-1 px-6">
                    <MusicProgress
                        currentTime={currentTime}
                        duration={duration}
                        onSeek={setCurrentTime}
                    />
                </div>

                <div className="flex items-center gap-6 w-full md:w-auto">
                    <div className="flex items-center gap-2">
                        <VolumeIcon />
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={volume}
                            onChange={handleVolumeChange}
                            className="w-28 h-1 bg-[#446dfc] rounded-lg appearance-none cursor-pointer"
                        />
                    </div>
                    <button className="px-4 py-2 rounded-lg bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium transition-colors">
                        Download
                    </button>
                    <div onClick={redirect} className="cursor-pointer">
                        <div className="relative group cursor-pointer">
                            <ExpandIcon />
                            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 text-xs text-white bg-black rounded whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity">
                                Full Screen
                            </div>
                        </div>
                    </div>
                    <button onClick={onClose} className="text-gray-400 transition-colors">
                        <CancelIcon />
                    </button>
                </div>
            </div>
        </div>
    );
}
