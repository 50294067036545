import { useState } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import popImage from '../../assets/MusicList/Browse.jpg'
import MusicGrid from '../BrowseMusic/musicgrid'
import Sidebar from '../BrowseMusic/sidebar'

export default function BrowseMusic() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className="bg-white font-sans">
      <div className="relative h-full w-full overflow-hidden md:h-64">
        <img
          src={popImage}
          alt="Concert lights"
          className="h-full w-full object-cover rounded-sm"
        />
      </div>
      <section className="container mx-auto">
        <div className="flex mt-6 gap-6">
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="fixed left-0 top-1/2 z-50 -translate-y-1/2 rounded-r-lg bg-blue-600 p-2 text-white focus:outline-none md:hidden"
          >
            {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
          </button>
          <div
            className={`${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
              fixed left-0 top-0 md:z-0 lg:z-0 z-40 h-screen w-64 transform md:bg-white bg-gray-100  transition-transform duration-200 ease-in-out md:relative md:translate-x-0`}
          >
            <Sidebar />
          </div>
          <div className="flex-1">
            <div className="h-full">
              <MusicGrid />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
