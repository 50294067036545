import React from 'react';
import HomePage from '../components/Home/index'
import MusicList from '../components/MusicList/index'
import WhyDigitalBridge from '../components/whyDigitalBridge';
import FeaturedTrack from '../components/FeaturedTrack/index'
import Faqs from '../components/Faqs/index'

function Home() {
    return (
        <>
            <HomePage />
            <MusicList />
            <WhyDigitalBridge />
            <FeaturedTrack />
            <Faqs />
        </>
    );
}

export default Home;
