import React from 'react';
import { PlayIcon } from '../../assets/icons/index';
import Pop from '../../assets/MusicList/Pop.png';
import Electronic from '../../assets/MusicList/Electronic.png';
import HipHop from '../../assets/MusicList/Hip Hop.png';
import Cinematic from '../../assets/MusicList/Cinematic.png';
import Classical from '../../assets/MusicList/Classical.png';

function Index() {
    const tracks = [
        { title: 'A Sky Full of Stars', artist: 'Coldplay', image: Pop },
        { title: 'Sorry', artist: 'Justin Bieber', image: Electronic },
        { title: 'Without ME', artist: 'Eminem', image: HipHop },
        { title: 'Black Space', artist: 'Taylor Swift', image: Cinematic },
        { title: 'Umbrella', artist: 'Rihanna', image: Classical },
    ];

    return (
        <section className="font-sans bg-white py-12">
            <div className="container mx-auto sm:px-4 md:px-0 lg:px-0">
                <div className="flex flex-wrap justify-between items-center mb-6">
                    <h2 className="text-[#1e2022] text-3xl sm:text-4xl lg:text-5xl font-bold font-['Montserrat'] leading-[1.2]">
                        Featured Tracks
                    </h2>
                    <div className="text-[#446dfc] text-lg sm:text-xl font-bold font-['Montserrat'] leading-7 cursor-pointer">
                        Show all
                    </div>
                </div>

                {/* Tracks Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
                    {tracks.map((track, index) => (
                        <div
                            key={index}
                            className="rounded-sm overflow-hidden flex flex-col group"
                        >
                            {/* Track Image */}
                            <div className="relative h-48 sm:h-56 overflow-hidden">
                                <img
                                    src={track.image}
                                    alt={track.title}
                                    className="w-full h-full object-cover transform group-hover:scale-105 transition duration-300 ease-in-out"
                                />
                                <div className="absolute bottom-2 right-2">
                                    <button className="w-10 h-10 sm:w-12 sm:h-12 bg-white rounded-full flex justify-center items-center shadow-lg transform group-hover:scale-110 transition duration-300 ease-in-out">
                                        <PlayIcon className="w-5 h-5 sm:w-6 sm:h-6 text-black" />
                                    </button>
                                </div>
                            </div>

                            {/* Track Info */}
                            <div className="pt-2">
                                <h3 className="text-[#1e2022] text-lg sm:text-xl font-bold font-['Montserrat'] leading-6 sm:leading-7">
                                    {track.title}
                                </h3>
                                <p className="text-[#1e2022] text-sm sm:text-base font-normal font-['Montserrat'] leading-normal">
                                    {track.artist}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Index;
