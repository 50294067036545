import React from 'react';
import { Link } from 'react-router-dom';
import { FacebookIcon, InstagramIcon, TwitterIcon, YouTubeIcon, LinkedInIcon } from '../../assets/icons/index';
import myIcon from '../../assets/icons/Logo.png';

const Footer = () => {
    return (
        <footer className="w-full bg-white border-t border-[#c8d4fe]">
            <div className="container mx-auto py-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
                <div className="space-y-6">
                    <div className="flex items-center space-x-2">
                        <Link to="/" className="flex items-center h-[2rem]">
                            <img src={myIcon} alt="Digital Bridge Logo" className="w-full h-full object-contain" />
                        </Link>
                    </div>
                    <p className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed sm:pr-0 md:pr-[96px]">
                        Digital Bridge is the best place to find royalty-free music that pops your videos up!
                    </p>
                    <div className="flex space-x-4">
                        <Link to="#" className="text-[#1e2022] hover:text-blue-500">
                            <FacebookIcon />
                            <span className="sr-only">Facebook</span>
                        </Link>
                        <Link to="#" className="text-[#1e2022] hover:text-blue-500">
                            <InstagramIcon />
                            <span className="sr-only">Instagram</span>
                        </Link>
                        <Link to="#" className="text-[#1e2022] hover:text-blue-500">
                            <TwitterIcon />
                            <span className="sr-only">Twitter</span>
                        </Link>
                        <Link to="#" className="text-[#1e2022] hover:text-blue-500">
                            <YouTubeIcon />
                            <span className="sr-only">YouTube</span>
                        </Link>
                        <Link to="#" className="text-[#1e2022] hover:text-blue-500">
                            <LinkedInIcon />
                            <span className="sr-only">LinkedIn</span>
                        </Link>
                    </div>
                </div>

                {/* Pages */}
                <div>
                    <h3 className="text-[#1e2022] text-lg font-bold font-['Montserrat'] leading-6 mb-6">Pages</h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to="#" className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                                Browse Music
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                                Why Digital Bridge
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                                FAQs
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Legal */}
                <div>
                    <h3 className="text-[#1e2022] text-lg font-bold font-['Montserrat'] leading-6 mb-6">Legal</h3>
                    <ul className="space-y-3">
                        <li>
                            <Link to="#" className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                                Data Privacy
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                                Terms of Service
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Contact Information */}
                <div>
                    <h3 className="text-[#1e2022] text-lg font-bold font-['Montserrat'] leading-6 mb-6">Contact Information</h3>
                    <ul className="space-y-3">
                        <li className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                            hello@digitalbridge.com
                        </li>
                        <li className="text-[#323232] text-base font-normal font-['Montserrat'] leading-relaxed">
                            Solutions LLC<br />
                            1234 Roost, Weston, FL<br />
                            33224
                        </li>
                    </ul>
                </div>
            </div>
            <div className="border-t border-[#c8d4fe] mt-6">
                <div className="container mx-auto px-6 py-4">
                    <p className="text-center text-[#323232] text-sm font-normal font-['Montserrat'] leading-none">
                        Copyright © {new Date().getFullYear()} DigitalBridge. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
