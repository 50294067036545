import React from 'react'
import { PauseSongIcon } from '../../assets/icons/index';
import { useLocation } from "react-router-dom";

export function TrackDetails({ onBack }) {
    const location = useLocation();
    const track = location.state?.track;

    if (!track) return <p>No track data available</p>;

    const genres = ['Pop']
    const moods = ['Hopeful', 'Touching', 'Caring', 'Loving', 'Inspiring']
    const themes = ['Advertising', 'Vlog', 'Travel', 'Wedding', 'Wellness']
    const instruments = ['Piano', 'Strings']

    return (
        <div className=" bg-white">
            <div className="pt-12">
                <div className="flex flex-col items-center">
                    <div className="w-48 h-48 rounded-sm overflow-hidden mb-6">
                        <img
                            src={track.image}
                            alt={track.title}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex items-center gap-2 mb-1">
                        <PauseSongIcon />
                        <h1 className="text-[#1e2022] text-xl font-bold font-['Montserrat'] leading-7">{track.title}</h1>
                    </div>
                    <p className="text-[#1e2022] text-base font-normal font-['Montserrat'] leading-normal mb-4">
                        Royalty Free Music by <span className="text-[#446dfc] text-base font-semibold font-['Montserrat'] leading-normal">{track.artist}</span>
                    </p>
                    <div className="h-12 px-6 py-3 bg-[#446dfc] rounded-lg justify-center items-center gap-1 inline-flex mb-12">
                        <div className="text-center text-white text-base font-bold font-['Montserrat'] leading-normal">Download</div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 md:px-16 px-4  gap-6  bg-[#ebf5ff] py-10 mt-8">
                    <div>
                        <h2 className="text-[#1e2022] text-lg md:text-xl font-bold font-['Montserrat'] leading-7 mb-2">Genre</h2>
                        <div className="flex flex-wrap gap-2">
                            {genres.map((genre, index) => (
                                <span
                                    key={index}
                                    className="px-4 py-2 rounded-full border border-[#6d6d6d] text-sm text-gray-700"
                                >
                                    {genre}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h2 className="text-[#1e2022] text-lg md:text-xl font-bold font-['Montserrat'] leading-7 mb-2">Mood</h2>
                        <div className="flex flex-wrap gap-2">
                            {moods.map((mood, index) => (
                                <span
                                    key={index}
                                    className="px-4 py-2 rounded-full border border-[#6d6d6d] text-sm text-gray-700"
                                >
                                    {mood}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h2 className="text-[#1e2022] text-lg md:text-xl font-bold font-['Montserrat'] leading-7 mb-2">Theme</h2>
                        <div className="flex flex-wrap gap-2">
                            {themes.map((theme, index) => (
                                <span
                                    key={index}
                                    className="px-4 py-2 rounded-full border border-[#6d6d6d] text-sm text-gray-700"
                                >
                                    {theme}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h2 className="text-[#1e2022] text-lg md:text-xl font-bold font-['Montserrat'] leading-7 mb-2">Instrument</h2>
                        <div className="flex flex-wrap gap-2">
                            {instruments.map((instrument, index) => (
                                <span
                                    key={index}
                                    className="px-4 py-2 rounded-full border border-[#6d6d6d] text-sm text-gray-700"
                                >
                                    {instrument}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

