import { useState } from 'react'
import { EmailIcon, ChevronRightIconIcon } from '../../assets/icons/index'

export default function Index() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        // Handle form submission here
        console.log('Form submitted:', formData)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <div className="max-w-2xl mx-auto px-4 py-12">
            <h1 className="text-[#1e2022] text-5xl font-bold font-['Montserrat'] leading-[56px] text-center mb-4">Contact Us</h1>
            <p className="text-center text-[#323232] text-xl font-normal font-['Montserrat'] leading-7 mb-5">
                For inquiries, email us or use our contact form.
            </p>
            <div className="bg-white rounded-lg p-6 border border-[#d8d8d8]">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label htmlFor="name" className="block text-[#1e2022] text-base font-bold font-['Montserrat'] leading-normal mb-2">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Mike Morgan"
                                className="w-full px-4 py-2  border border-[#d8d8d8] rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition text-[#999999] text-xl font-normal font-['Montserrat'] leading-7"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-[#1e2022] text-base font-bold font-['Montserrat'] leading-normal mb-2">
                                Email address
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="name@gmail.com"
                                className="w-full px-4 py-2  border border-[#d8d8d8] text-[#999999] text-xl font-normal font-['Montserrat'] leading-7 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="subject" className="block text-[#1e2022] text-base font-bold font-['Montserrat'] leading-normal mb-2">
                            Subject
                        </label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            placeholder="Enter your subject"
                            className="w-full px-4 py-2 text-[#999999] text-xl font-normal font-['Montserrat'] leading-7  border border-[#d8d8d8] rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition"
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-[#1e2022] text-base font-bold font-['Montserrat'] leading-normal mb-2">
                            Message
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Enter your message"
                            rows="4"
                            className="w-full px-4 py-2 text-[#999999] text-xl font-normal font-['Montserrat'] leading-7 border border-[#d8d8d8] rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition"
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="w-full h-14 text-center text-white text-xl font-bold font-['Montserrat'] leading-7 md:w-auto px-7 py-3 bg-[#446dfc] rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition"
                        >
                            Send Your Message
                        </button>
                    </div>

                </form>
            </div>
            <div className="mt-5 text-center">
                <div className="flex items-center mb-5 justify-center space-x-2 sm:space-x-4">
                    <div className="w-full h-px bg-[#d8d8d8]" />
                    <p className="text-center text-neutral-700 text-xs sm:text-sm font-bold font-['Montserrat'] leading-tight">or</p>
                    <div className="w-full h-px bg-[#d8d8d8]" />
                </div>
                <a
                    href="mailto:hello@digitalbridge.com"
                    className="w-full inline-flex flex-wrap sm:flex-nowrap items-center justify-between px-4 sm:px-6 py-3 sm:py-4 bg-[#ebf5ff] text-gray-700 rounded-lg hover:bg-blue-100 transition group"
                >
                    <div className="flex items-center gap-2 sm:gap-4">
                        <div className="w-[50px] sm:w-[60px] h-[50px] sm:h-[60px] p-2 sm:p-3 bg-white rounded-lg border border-[#c8d4fe] flex justify-center items-center">
                            <EmailIcon />
                        </div>
                        <div className="text-left">
                            <p className="text-[#323232] text-base sm:text-xl font-normal font-['Montserrat'] leading-6 sm:leading-7">Email us</p>
                            <p className="text-[#323232] text-base sm:text-xl font-bold font-['Montserrat'] leading-6 sm:leading-7">hello@digitalbridge.com</p>
                        </div>
                    </div>
                    <ChevronRightIconIcon className="group-hover:translate-x-1 transition" />
                </a>
            </div>

        </div>
    )
}
