import React from "react"
import { PlayIcon } from '../../assets/icons/index';
import { AudioPlayer } from "./audioplayer"
import Pop from '../../assets/MusicList/Pop.png';
import Electronic from '../../assets/MusicList/Electronic.png';
import HipHop from '../../assets/MusicList/Hip Hop.png';
import Cinematic from '../../assets/MusicList/Cinematic.png';
import { ChevronRightIcon, ChevronLeftIcon } from '../../assets/icons/index'

export default function TrackList() {
    const tracks = [
        { title: 'A Sky Full of Stars', artist: 'Coldplay', image: Pop },
        { title: 'Sorry', artist: 'Justin Bieber', image: Electronic },
        { title: 'Without ME', artist: 'Eminem', image: HipHop },
        { title: 'Black Space', artist: 'Taylor Swift', image: Cinematic },
        { title: 'A Sky Full of Stars', artist: 'Coldplay', image: Pop },
        { title: 'Sorry', artist: 'Justin Bieber', image: Electronic },
        { title: 'Without ME', artist: 'Eminem', image: HipHop },
        { title: 'Black Space', artist: 'Taylor Swift', image: Cinematic },
        { title: 'A Sky Full of Stars', artist: 'Coldplay', image: Pop },
        { title: 'Sorry', artist: 'Justin Bieber', image: Electronic },
        { title: 'Without ME', artist: 'Eminem', image: HipHop },
        { title: 'Black Space', artist: 'Taylor Swift', image: Cinematic },
        { title: 'A Sky Full of Stars', artist: 'Coldplay', image: Pop },
        { title: 'Sorry', artist: 'Justin Bieber', image: Electronic },
        { title: 'Without ME', artist: 'Eminem', image: HipHop },
        { title: 'Black Space', artist: 'Taylor Swift', image: Cinematic },
    ];

    const [currentPage, setCurrentPage] = React.useState(1)
    const [selectedTrack, setSelectedTrack] = React.useState(null)
    const tracksPerPage = 12
    const totalPages = Math.ceil(tracks.length / tracksPerPage)
    const indexOfLastTrack = currentPage * tracksPerPage
    const indexOfFirstTrack = indexOfLastTrack - tracksPerPage
    const currentTracks = tracks.slice(indexOfFirstTrack, indexOfLastTrack)

    const handleTrackClick = (track) => {
        setSelectedTrack(track)
    }

    const handleTrackDetailsClick = (track) => {
        setSelectedTrack(track);

    };

    const getPageNumbers = () => {
        const pages = []
        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i)
            }
        } else {
            if (currentPage <= 3) {
                pages.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages)
            } else if (currentPage >= totalPages - 2) {
                pages.push(1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages)
            } else {
                pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages)
            }
        }
        return pages
    }
    return (
        <section className="font-sans bg-white pb-24">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {currentTracks.map((track, index) => (
                    <div
                        key={index}
                        className="rounded-sm flex flex-col group cursor-pointer"
                        onClick={() => handleTrackClick(track)}
                    >
                        <div className="relative h-48 sm:h-56">
                            <img
                                src={track.image}
                                alt={track.title}
                                className="w-full h-full object-cover transform group-hover:scale-105 transition duration-300 ease-in-out"
                            />
                            <div className="absolute bottom-2 right-2">
                                <button className="w-10 h-10 sm:w-12 sm:h-12 bg-white rounded-full flex justify-center items-center shadow-lg transform group-hover:scale-110 transition duration-300 ease-in-out">
                                    <PlayIcon />
                                </button>
                            </div>
                        </div>
                        <div className="pt-2">
                            <h3 className="text-[#1e2022] text-lg sm:text-xl font-bold font-['Montserrat'] leading-6 sm:leading-7">
                                {track.title}
                            </h3>
                            <p className="text-[#1e2022] text-sm sm:text-base font-normal font-['Montserrat'] leading-normal">
                                {track.artist}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex justify-center items-center gap-2 mt-8">
                <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-blue-50 text-blue-600 disabled:opacity-50"
                >
                    <ChevronRightIcon />
                </button>
                {getPageNumbers().map((number, index) => (
                    <button
                        key={index}
                        onClick={() => typeof number === 'number' && setCurrentPage(number)}
                        className={`w-10 h-10 flex items-center justify-center rounded-lg text-[#1e2022] text-xl font-bold font-['Montserrat'] leading-7 ${number === currentPage
                            ? 'bg-blue-600 text-white'
                            : number === '...'
                                ? 'bg-transparent'
                                : 'bg-blue-50 text-[#1e2022]'
                            }`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-blue-50 text-blue-600 disabled:opacity-50"
                >
                    <ChevronLeftIcon />
                </button>
            </div>
            <AudioPlayer
                track={selectedTrack}
                onClose={() => setSelectedTrack(null)}
                onTrackClick={handleTrackDetailsClick}
            />
        </section>
    )
}

