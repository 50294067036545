import React from 'react';
import ContactPage from '../components/Contact/index';

function ContactUs () {
  return (
    <ContactPage />
  );
}

export default ContactUs;
