import { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '../../assets/icons/index'

const genres = [
    'Acoustic',
    'Ambient',
    'Children',
    'Chill Out',
    'Cinematic',
    'Corporate',
    'Electronic',
    'Flok',
    'Funk / Groove',
    'Hip-Hop / R&B',
    'Indie',
    'Jazz',
    'Pop',
    'Rock',
];

export default function Sidebar() {
    const [openSections, setOpenSections] = useState({
        genre: true,
        mood: false,
        theme: false,
        instrument: false,
    });

    const toggleSection = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    return (
        <div className="flex h-full flex-col text-gray-300 p-6 md:p-0">
            <h3 className="text-[#1e2022] text-sm font-bold font-['Montserrat'] leading-tight mb-2">
                Search
            </h3>
            <div className="relative mb-2">
                <input
                    type="text"
                    placeholder="Search Tracks & Artists"
                    className="w-full rounded border border-[#6d6d6d] font-normal font-['Montserrat'] leading-tight px-2 py-3 text-sm text-[#1e2022] focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
            </div>
            <div className="space-y-2 mt-2">
                <div className="w-full h-px bg-[#d0d3d6]" />
                <div>
                    <h3
                        className="flex justify-between items-center text-[#1e2022] text-sm font-bold font-['Montserrat'] leading-tight mb-2 cursor-pointer"
                        onClick={() => toggleSection('genre')}
                    >
                        Genre
                        <span>{openSections.genre ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
                    </h3>
                    {openSections.genre && (
                        <div className="space-y-2">
                            {genres.map((genre) => (
                                <label key={genre} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="w-5 h-5 rounded-sm border border-[#6d6d6d]"
                                    />
                                    <span className="text-[#1e2022] text-sm font-normal font-['Montserrat'] leading-tight">{genre}</span>
                                </label>
                            ))}
                        </div>
                    )}
                    <div className="w-full h-px bg-[#d0d3d6] my-4" />
                </div>
                <div>
                    <h3
                        className="flex justify-between items-center text-[#1e2022] text-sm font-bold font-['Montserrat'] leading-tight mb-2 cursor-pointer"
                        onClick={() => toggleSection('mood')}
                    >
                        Mood
                        <span>{openSections.mood ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
                    </h3>

                    <div className="w-full h-px bg-[#d0d3d6] my-4" />
                </div>
                <div>
                    <h3
                        className="flex justify-between items-center text-[#1e2022] text-sm font-bold font-['Montserrat'] leading-tight mb-2 cursor-pointer"
                        onClick={() => toggleSection('theme')}
                    >
                        Theme
                        <span>{openSections.theme ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
                    </h3>
                    <div className="w-full h-px bg-[#d0d3d6] my-4" />
                </div>
                <div>
                    <h3
                        className="flex justify-between items-center text-[#1e2022] text-sm font-bold font-['Montserrat'] leading-tight mb-2 cursor-pointer"
                        onClick={() => toggleSection('instrument')}
                    >
                        Instrument
                        <span>{openSections.instrument ? <ChevronUpIcon /> : <ChevronDownIcon />}</span>
                    </h3>
                </div>
            </div>
        </div>
    );
}
