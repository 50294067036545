import React from "react"
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../Ui/accordian"
import image1 from '../../assets/faqImages/image1.jpeg'
import image2 from '../../assets/faqImages/image2.jpeg'
import image3 from '../../assets/faqImages/images3.jpeg'

export default function FAQSection() {
    return (
        <section className="bg-[#EBF5FF] font-sans" id="faqs">
            <div className="container  mx-auto py-16">
                <div className="text-center mb-12">
                    <h2 className="text-center text-[#1e2022] text-5xl font-bold font-['Montserrat'] leading-[56px] mb-4">Frequently asked questions</h2>
                    <p className="text-center text-[#323232] text-xl font-normal font-['Montserrat'] leading-7">
                        Ask everything you need to know about our product and services.
                    </p>
                </div>
                <Accordion type="single" collapsible className="mb-12">
                    <AccordionItem value="item-1">
                        <AccordionTrigger>Q. Is Digital Bridge free?</AccordionTrigger>
                        <AccordionContent>
                            Yes, Digital Bridge offers a free tier with basic features. Premium features are available with paid subscriptions.
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-2">
                        <AccordionTrigger>Q. Will I lose my AdSense revenue?</AccordionTrigger>
                        <AccordionContent>
                            No, you will not lose your AdSense revenue. Digital Bridge is designed to work alongside your existing monetization methods.
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-3">
                        <AccordionTrigger>Q. How do I get started?</AccordionTrigger>
                        <AccordionContent>
                            Getting started is easy. Simply sign up for an account, complete your profile, and follow our quick setup guide.
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-4">
                        <AccordionTrigger>Q. How does Digital Bridge make money?</AccordionTrigger>
                        <AccordionContent>
                            Digital Bridge generates revenue through premium subscriptions and partnership programs while maintaining free services.
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="item-5">
                        <AccordionTrigger>Q. Is Digital Bridge Music guaranteed to make me viral?</AccordionTrigger>
                        <AccordionContent>
                            While we provide tools and strategies to increase your reach, viral success depends on multiple factors and cannot be guaranteed.
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>

                <div className="bg-[#d6eaff]  rounded-lg border border-[#c8d4fe] p-8 text-center">
                    <div className="flex justify-center -space-x-4 mb-4">
                        <img
                            src={image1}
                            alt="Team member"
                            className="w-10 h-10 rounded-full border-2 border-white object-cover"
                        />
                        <img
                            src={image2}
                            alt="Team member"
                            className="w-10 h-10 rounded-full border-2 border-white object-cover"
                        />
                        <img
                            src={image3}
                            alt="Team member"
                            className="w-10 h-10 rounded-full border-2 border-white object-cover"
                        />
                    </div>
                    <h3 className="text-2xl font-bold mb-6">Still have questions?</h3>
                    <p className="text-gray-600 mb-6">
                        Can't find answer you're looking for? Please contact with our friendly team.
                    </p>
                    <button className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors">
                        Contact Us
                    </button>
                </div>
            </div>
        </section>
    )
}

