import React from "react"
import { Slider } from "../Ui/slider"

export function MusicProgress({ currentTime, duration, onSeek, className }) {
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  return (
    <div className={`w-full flex align-item-center gap-3 ${className || ''}`}>
      <div className="md:text-[#1e2022] text-sm font-normal font-['Montserrat'] leading-tight sm:text-white">
        {formatTime(currentTime)}
      </div>
      <Slider
        value={[currentTime]}
        max={duration}
        step={1}
        onValueChange={(value) => onSeek?.(value[0])}
      />
      <div className="md:text-[#1e2022] text-sm font-normal font-['Montserrat'] leading-tight sm:text-white">
        {formatTime(duration)}
      </div>
    </div>
  )
}
