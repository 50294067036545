import React from 'react';
import { BrushIcon, PlainIcon, EmojiIcon } from '../../assets/icons/index';

const WhyDigitalBridge = () => {
    const features = [
        {
            number: "01",
            icon: <EmojiIcon />,
            title: "All Your Emotions in One Library",
            description: "Find every track in one easy-to-navigate collection."
        },
        {
            number: "02",
            icon: <PlainIcon />,
            title: "Boost Your Reach Globally",
            description: "Discover music that's trending worldwide."
        },
        {
            number: "03",
            icon: <BrushIcon />,
            title: "Perfect for Background Use",
            description: "Our tracks are paced just right and free of distracting lyrics."
        }
    ];

    return (
        <section className="w-full bg-[#ebf5ff] py-14" id='why-digital-bridge'>
            <div className="container mx-auto">
                <h2 className="text-[#1e2022] text-3xl sm:text-4xl md:text-5xl font-bold leading-tight mb-6">
                    Why Digital Bridge
                </h2>
                <div className="h-px bg-[#e5e7eb] mb-8 mx-auto w-full" />
                <div className="space-y-10">
                    {features.map((feature, index) => (
                        <div key={feature.number} className="space-y-6">
                            <div className=" flex gap-4 md:grid grid-cols-1 w-full md:grid-cols-12  items-start">
                                <div className="md:col-span-5 text-[#1e2022] text-2xl sm:text-[20px] md:text-[32px] font-bold font-['Montserrat'] leading-10">
                                    {feature.number}
                                </div>
                                <div className="md:col-span-7">
                                    <div className="flex items-start gap-4">
                                        {feature.icon}
                                        <div>
                                            <h3 className="text-[#1e2022] text-xl sm:text-2xl md:text-[32px] font-bold font-['Montserrat'] leading-10">
                                                {feature.title}
                                            </h3>
                                            <p className="text-[#323232] text-base sm:text-lg md:text-xl font-normal font-['Montserrat'] leading-7 mt-4">
                                                {feature.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {index < features.length - 1 && (
                                <div className="h-px bg-[#e5e7eb] mt-8" />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WhyDigitalBridge;
